import { Component, HostListener } from '@angular/core';
import { ApiHelperService } from './services/api-helper.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

  constructor(private apiHelperService: ApiHelperService) { }

  title = 'easyink-frontend';
}
