import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Constants } from '../utilities/Constants';
import { GlobalService } from './global.service';
import { ObjectUtil } from '../utilities/objectUtil';

@Injectable({
  providedIn: 'root'
})
export class ApiHelperService {

  constructor(
    private httpClient: HttpClient,
    private globalService: GlobalService
  ) { }
  private getHeaders(isTokenRequired: boolean, customHeaders?: HttpHeaders, isMultipartRequest?: boolean) {
    let httpHeaders = new HttpHeaders();
    if (customHeaders) {
      httpHeaders = customHeaders;
    }
    if (isMultipartRequest) {
      // httpHeaders = httpHeaders.append(Constants.CONTENT_TYPE_KEY, Constants.MULTIPART_FORM_DATA);
    } else {
      httpHeaders = httpHeaders.append(Constants.CONTENT_TYPE_KEY, Constants.APPLICATION_JSON);
    }
    if (isTokenRequired && ObjectUtil.isNotEmpty(this.globalService.getAccessToken())) {
      httpHeaders = httpHeaders.append(Constants.AUTHORIZATION_KEY, 'Bearer ' + this.globalService.getAccessToken());
    }
    return httpHeaders;
  }
  post(endpoint: string, data: any, isTokenRequired: boolean, params?: HttpParams, customHeaders?: HttpHeaders) {
    const options = {};
    options['headers'] = this.getHeaders(isTokenRequired, customHeaders);
    if (params) {
      options['params'] = params;
    }
    return this.httpClient.post(endpoint, JSON.stringify(data), options);
  }
  put(endpoint: string, data: any, isTokenRequired: boolean, params?: HttpParams, customHeaders?: HttpHeaders) {
    const options = {};
    options['headers'] = this.getHeaders(isTokenRequired, customHeaders);
    if (params) {
      options['params'] = params;
    }
    return this.httpClient.put(endpoint, JSON.stringify(data), options);
  }
  get(endpoint: string, isTokenRequired: boolean, params?: HttpParams, customHeaders?: HttpHeaders) {
    const options = {};
    options['headers'] = this.getHeaders(isTokenRequired, customHeaders);
    if (params) {
      options['params'] = params;
    }
    return this.httpClient.get(endpoint, options);
  }

  postMultipartRequest(endpoint: string, data: any, isTokenRequired: boolean, params?: HttpParams, customHeaders?: HttpHeaders) {
    const options = {};
    options['headers'] = this.getHeaders(isTokenRequired, customHeaders, true);
    if (params) {
      options['params'] = params;
    }
    return this.httpClient.post(endpoint, data, options);
  }

  delete(endpoint: string, isTokenRequired: boolean, params?: HttpParams, customHeaders?: HttpHeaders) {
    const options = {};
    options['headers'] = this.getHeaders(isTokenRequired, customHeaders);
    if (params) {
      options['params'] = params;
    }
    return this.httpClient.delete(endpoint, options);
  }
}
