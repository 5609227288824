import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuardService } from './services/auth-guard.service';

const routes: Routes = [{
  path: '',
  redirectTo: 'login',
  pathMatch: 'full'
}, {
  path: '',
  canActivate: [AuthGuardService],
  children: [{
    path: 'main',
    loadChildren: 'src/app/pages/main/main.module#MainModule'
  }]
}, {
  path: 'login',
  loadChildren: 'src/app/pages/login/login.module#LoginModule'
}, {
  path: 'sign/:linkToken',
  loadChildren: 'src/app/pages/sign/sign.module#SignModule',
  pathMatch: 'full'
}, {
  path: 'guestUser',
  loadChildren: 'src/app/pages/guestuser/guestuser.module#GuestuserModule'
}];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
