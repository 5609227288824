import { Injectable } from '@angular/core';
import { Constants } from '../utilities/Constants';
import { ObjectUtil } from '../utilities/objectUtil';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class GlobalService {

  constructor(private router: Router) { }

  getAccessToken() {
    return localStorage.getItem(Constants.ACCESS_TOKEN_KEY);
  }
  setAccessToken(accessToken) {
    localStorage.setItem(Constants.ACCESS_TOKEN_KEY, accessToken);
  }
  isAuthenticated() {
    return ObjectUtil.isNotEmpty(this.getAccessToken());
  }

  setUserProfile(userProfile) {
    localStorage.setItem(Constants.USER_PROFILE_KEY, JSON.stringify(userProfile));
  }
  getUserProfile() {
    return JSON.parse(localStorage.getItem(Constants.USER_PROFILE_KEY));
  }
  logout() {
    localStorage.clear();
    this.router.navigateByUrl('');
  }

}
