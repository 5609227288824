export class ObjectUtil {
    static isNotEmpty(obj: any) {
        if (!obj || obj == null) {
            return false;
        }
        if (obj instanceof String) {
            return obj !== '';
        } else if (obj instanceof Array) {
            return obj.length > 0;
        }
        return true;
    }
}
